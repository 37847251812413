import React, { useState, useEffect } from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player/lazy'
import ModalTiny from "react-bootstrap/Modal"
import { isMobile } from "react-device-detect";
const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return (
        createPortal(
            children,
            el
        )
    )
}

const Player = ({srcUrl, control, muted, repeat}) => {
  return (
    <ReactPlayer
      url={srcUrl}
      width="100%"
      height="100%"
      playing
      controls={control}
      muted={muted}
      loop={repeat}
      playsinline={true}
    />
  )
}

const CustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [muted, setMuted] = useState(false);
    const [control, setControl] = useState(true);

    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    useEffect(() => {
        if (props.autoplay) {
            setOpen(true);
            setMuted(true)
            setControl(false)
        }
        if (isMobile) {
            setMuted(true)
        }
    }, [props.autoplay])

    return (
        <>

            <div className="banner-btn">
                {props.type == "button" && !isOpen &&
                    <Button type="button" variant="link" className="video-btn" onClick={() => { setOpen(true); trackerVideo(props.title); }} onKeyDown={() => setOpen(true)}>
                        <i className={props.icon ? props.icon : "video-icon"}></i>
                        {props.buttonName &&
                            <>{props.buttonName}</>
                        }
                    </Button>
                }
                {props.type != "button" && !isOpen &&
                    <strong className={props.class ? props.class : "video-btn"} onClick={() => { setOpen(true); trackerVideo(props.title); }} onKeyDown={() => setOpen(true)}>
                        <i className={props.icon ? props.icon : "video-icon"}></i>
                        {props.buttonName &&
                            <>{props.buttonName}</>
                        }
                    </strong>
                }
            </div>

            {isOpen && (
                props.fullscreen ? (
                    <ModalTiny show={isOpen} onHide={() => setOpen(false)} className="player-modal">
                        <ModalTiny.Header className="contact-close-btn ">
                            <span class="close-menu" onClick={() => setOpen(false)} ><i class="icon-close"></i></span>
                        </ModalTiny.Header>
                            <ModalTiny.Body className="event-section">
                                <Player srcUrl={props.url} control={control} muted={muted} repeat={props.repeat} />
                            </ModalTiny.Body>
                    </ModalTiny>
                ) : (
                    <div className="inline-player">
                        {!props.autoplay &&
                            <div className="close-icon" onClick={() => { setOpen(false); }}>
                                <i className="icon-close"></i>
                            </div>
                        }
                        <Player srcUrl={props.url} control={control} muted={muted} repeat={props.repeat} />
                    </div>
                )
            )}
        </>
    )
}

export default CustomVideo

